/* eslint-disable eqeqeq */
import React from "react";
import { v4 as uuid } from "uuid";
import HttpService from "../utils/Http.services";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../stores/actionsList";
// import TabBar from "../views/TabBar";
import Configurer from "../components/Configurer";
import SmartInput from "../components/inputs/SmartInput";
import Modal from "../components/Modal";
import ResultModal from "../modals/ResultModal";
import Loader from "react-loader-spinner";
import {
    pathMaker,
    removeSpaces,
    replaceParenthesisWithItsLetter,
    setObject,
} from "../utils/useful";
import Collapsible from "react-collapsible";
import { nanoid } from "nanoid";
import ModelAdditionalParams from "../modals/ModelAdditionalParams";
import * as equationsFuctions from "../components/inputs/sigma";
import ReactDOMServer from "react-dom/server";
import "../styles/editor.scss";

import ExcelDefinedNames from "../components/ExcelDefinedNames/ExcelDefinedNames";
import SelectInput from "../components/SelectInput";

class Editor extends React.Component {
    state = {
        constraints: [],
        errors: [],
        variables: [],
        properVariables: [],
        indexes: [],
        generatedModelExtendedLines: [],
        tools: [
            {
                name: "Indexes",
                icon: "/images/icons/index.png",
                function: "openIndexesModal",
                stateKey: "indexes",
            },
            {
                name: "Variables",
                icon: "/images/icons/variable.png",
                function: "openProperVariables",
                stateKey: "properVariables",
            },
            {
                name: "Functions",
                icon: "/images/icons/function.svg",
                function: "OpenEquationModal",
            },
            {
                name: "Import",
                icon: "/images/icons/import.png",
                function: "ImportTextFile",
                eventName: "onChange",
            },
            {
                name: "Export",
                icon: "/images/icons/export.png",
                function: "ExportTextFile",
            },

            { name: "Charts", icon: "/images/icons/bar-chart-2.svg" },
            { name: "Defaults", icon: "/images/icons/table.svg" },
        ],

        equations: [
            {
                title: "Sigma",
                notation: "∑",
                key: "Sigma",
                shortcut: "Alt + S",
            },
            // { title: 'Multiple Sigmas', notation: '∑...∑', key: 'MSigma' },
            {
                title: "For All",
                notation: "∀",
                key: "ForAll",
                shortcut: "Alt + A",
            },
            {
                title: "Variable",
                notation: "INDEX",
                key: "Index",
                shortcut: "Alt + V",
            },
        ],
        isChecked: false,
        keyMap: {},
        indexValue: "",
        currentPage: 1,
        valuesPerPage: 5,
        forallCounter: 0,
    };
    finalOptimized = React.createRef();
    regexMiddleSection = new RegExp(/(?:max=|min=)/);
    beforeAfterEqation = new RegExp(/(=)|(>=)|(<=)/);
    operatorsRegex = new RegExp(/[+\-*\/=<>]+/);
    operationsRegex = new RegExp(/[+\-*\/]|<=|>=|=|</g);

    pipeRegex = new RegExp(/(?<=\|).*?(?=\||$)/g);
    componentDidMount() {
        const name = this.props.match?.params?.name;
        document.addEventListener("keyup", this.doc_keyUp);
        if (!name) {
            return this.nameModal.showModal();
        }
        this.getOneEquation({ name });
        if (this.props?.socket?.connected) {
            this.initSocketListeners()
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keyup", this.doc_keyUp);
        this.props.socket.off(actions.GENERATED_MODEL);
        this.props.socket.off(actions.SOLVED_MODEL);
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.socket.connected && ((prevState.name !== this.state.name) || ((this.props.socket.connected !== prevProps.socket.connected)))) {
            this.initSocketListeners()
        }
    }

    initSocketListeners = () => {
        this.props.socket.on(actions.GENERATED_MODEL, (data, cb) => {
            this.setState(
                { isModelBeingGeneratedLoader: false },
                () => {
                    if (!data?.success) {
                        return alert(
                            "Error while generating the model!"
                        );
                    }
                    this.setState(
                        {
                            generatedModelExtendedLines:
                                data?.parsedLines || [],
                        },
                        () => cb(true)
                    );
                }
            );
        });
        this.props.socket.on(actions.SOLVED_MODEL, (data) => {
            if (!data?.success) {
                return alert(data.message);
            }
            this.setState(
                {
                    solvedModelResultIsAvaialble: true,
                },
                () => {
                    alert(
                        "Model solved successfully. You can download the results now."
                    );
                }
            );
        });
    };

    getSmartInputValue = () => this.smartInput.getInputValue();
    getOneEquationFromClipboard = (clipBoardText) => {
        clipBoardText = clipBoardText?.split(";").join(";\n");
        this.setState(
            {
                isLoadingPage: true,
            },
            () => {
                const indexes = [...this.state?.indexes];
                this.convertTextToEquation(
                    clipBoardText,
                    indexes,
                    (finalText) => {
                        this.setState(
                            {
                                initData: finalText,
                            },
                            () => {
                                this.setState(
                                    { isLoadingPage: false },
                                    () => {
                                        this.detectLinesProperVariables();
                                    }
                                );
                            }
                        );
                    }
                );
            }
        );
    };
    getOneEquation = ({ name }) => {
        this.setState({ isLoadingPage: true }, () => {
            HttpService.request(
                "getOneEquation",
                { name },
                (fetchResult, fetchError) => {
                    if (fetchError) {
                        if (fetchError.status === 404) {
                            return this.nameModal.showModal();
                        }
                        return alert(fetchError?.message);
                    }

                    if (!fetchResult?.info?.modelName) {
                        this.props.history.push("/editor");
                        return this.nameModal.showModal();
                    }
                    const {
                        indexes,
                        nickName,
                        modelSmartInputContent,
                        helper,
                        properVariables,
                        isBeingGenerated,
                        parsedLines,
                        isBeingSolved,
                    } = fetchResult.info;
                    const linesText = modelSmartInputContent
                        ?.split(";")
                        .join(";\n");
                    this.convertTextToEquation(
                        linesText,
                        indexes,
                        (finalText) => {
                            this.setState(
                                {
                                    initData: finalText,
                                    nickname: nickName,
                                    history: fetchResult.info,
                                    helper,
                                    properVariables,
                                    isLoadingPage: false,
                                    name,
                                    indexes,
                                    isModelBeingSolvedLoader: isBeingSolved,
                                    generatedModelExtendedLines:
                                        parsedLines || [],
                                },
                                () => {
                                    this.detectLinesProperVariables();
                                    this.setState({
                                        isModelBeingGeneratedLoader: isBeingGenerated,
                                    });
                                }
                            );
                        }
                    );
                }
            );
        });
    };

    convertTextToEquation(text, indexes, cb) {
        this.equationSections(text, (sections) => {
            let final = "";
            sections.forEach((section) => {
                if (section.type == "text") {
                    final = final + section.content;
                } else {
                    this.convertEquationToHtml(
                        section.content,
                        indexes,
                        (html) => {
                            final = final + html?.outerHTML;
                        }
                    );
                }
            });
            cb(final);
        });
    }

    convertEquationToHtml(text, indexes, cb) {
        let html;
        const operatorRegex = /\#~(.*?)\~/;
        let found = text.match(operatorRegex);
        // //  // //
        // //
        // //
        if (found) {
            let operator = found[1];
            operator = operator.charAt(0).toUpperCase() + operator.slice(1);

            if (operator == "Sigma") {
                this.sigmaToHtml(text, (htmlRes, err) => {
                    html = htmlRes;
                });
            }

            if (operator == "Index") {
                this.indexToHtml(text, (htmlRes, err) => {
                    html = htmlRes;
                });
            }
            if (operator == "For_all") {
                this.forAllToHtml(text, (htmlRes, err) => {
                    html = htmlRes;
                });
            }
        }
        // //
        // //
        cb(html);
    }

    indexToHtml(content, cb) {
        let html;
        let bottomContent = null;
        let mainContent = "";
        let newContent = content.replace("#~index", "~");

        newContent = newContent.substring(0, newContent.length - 2);

        const contentRegex = /~~(.*?)~~/;
        let foundContent = newContent.match(contentRegex);

        mainContent = foundContent[1];
        newContent = content.replace(foundContent[0], "~~");
        foundContent = newContent.match(contentRegex);

        bottomContent = foundContent[1];

        // //  // //
        // //  // //
        // //  // //

        let operator = "Index";
        if (equationsFuctions[operator]) {
            html = equationsFuctions[operator]();

            var parser = new DOMParser();
            let newElement = parser.parseFromString(
                ReactDOMServer.renderToString(html),
                "text/html"
            );
            let span = newElement.querySelector("span");
            let contentSpan = span.querySelector(".sigma-front-content");
            let bottomSpan = span.querySelector(".sigma-bottom-content");

            if (mainContent != "") {
                contentSpan.textContent = mainContent;
            }

            if (bottomContent) {
                bottomSpan.textContent = bottomContent;
            }
            html = span;

            // //  // //
            // //  // //

            // //  // //
            //  // //
        }

        cb(html);
    }

    sigmaToHtml(content, cb) {
        // content = content.replace('∑', '')
        let html;
        // //  // //
        // //  // //
        let bottomContent = null;
        let mainContent = "";

        let newContent = content.replace("#~sigma~∑", "");
        newContent = newContent.substring(0, newContent.length - 2);

        this.equationSections(newContent, (sections) => {
            // //  // //

            const contentRegex = /~~(.*?)~~/;
            let foundIndex = sections[0].content.match(contentRegex);
            // //  // //
            if (foundIndex) {
                bottomContent = foundIndex[1];
                // //  // //

                // this.convertEquationToHtml(section.content, indexes, (html) => {
                //     //  // //
                //     final = final + html.outerHTML
                // })

                // let contentBody = content.replace('~~' + foundIndex + '~~', '')
            }

            for (let j = 1; j < sections.length; j++) {
                const section = sections[j];

                if (section.type == "text") {
                    mainContent = mainContent + section.content;
                } else {
                    //  // //
                    //  // //
                    //  // //

                    this.convertEquationToHtml(section.content, [], (chtml) => {
                        //  // //
                        mainContent = mainContent + (chtml.outerHTML ?? chtml);
                    });
                }
            }
        });

        let operator = "Sigma";
        if (equationsFuctions[operator]) {
            html = equationsFuctions[operator]();
            var parser = new DOMParser();
            let newElement = parser.parseFromString(
                ReactDOMServer.renderToString(html),
                "text/html"
            );
            let span = newElement.querySelector("span");
            let contentSpan = span.querySelector(".sigma-front-content");
            let bottomSpan = span.querySelector(".sigma-bottom-content");

            if (mainContent != "") {
                //  // //
                // if(typeof mainContent=='string'){
                contentSpan.innerHTML = mainContent;
                // }else{
                //     //  // //
                //     contentSpan.appendChild(mainContent)
                // }
            }

            if (bottomContent) {
                bottomSpan.textContent = bottomContent;
            }
            html = span;

            // //  // //
            // //  // //

            // //  // //
            // //  // //
        }

        cb(html);
    }

    forAllToHtml(content, cb) {
        let html;
        let bottomContent = null;
        let mainContent = "";
        let newContent = content.replace("#~for_all", "~");
        newContent = newContent.substring(0, newContent.length - 2);
        const contentRegex = /~~∀(.*)/;
        let foundContent = newContent.match(contentRegex);

        bottomContent = foundContent?.[1];
        let operator = "ForAll";
        if (equationsFuctions[operator]) {
            html = equationsFuctions[operator]();

            var parser = new DOMParser();
            let newElement = parser.parseFromString(
                ReactDOMServer.renderToString(html),
                "text/html"
            );
            let span = newElement.querySelector("span");
            let contentSpan = span.querySelector(".sigma-front-content");
            mainContent = bottomContent;
            if (mainContent != "") {
                contentSpan.textContent = mainContent;
            }
            //
            // if (bottomContent) {
            //     bottomSpan.textContent = bottomContent;
            // }
            html = span;

            // //  // //
            // //  // //

            // //  // //
            //  // //
        }

        cb(html);
    }
    OpenEquationModal = () => {
        this.equationsModal.showModal();
        // this.smartInput.addSigma()
    };

    insertEquation = (key) => {
        this.smartInput.insertEquation(key);
        this.equationsModal.hideModal();
    };

    ImportTextFile = async (e) => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "application/json";
        input.id = "**fileInput**";
        input.addEventListener("change", this.onChangeFile, false);
        input.click();
    };

    handleImportedTextFile = (text) => {
        const importedAdditonalParams = {};
        this.ModelAdditionalParamsList.forEach(
            ({ page: { exportTitle }, paramKey }) => {
                const paramsRgex = new RegExp(
                    `=== ${exportTitle} ===\\s*(.*?)\\s*=== ${exportTitle} ===`,
                    "s"
                );
                const paramsMatches = text.match(paramsRgex);
                if (paramsMatches && paramsMatches.length >= 2) {
                    const indexContent = paramsMatches[1];
                    const lines = indexContent
                        ?.trim()
                        ?.split("\n")
                        ?.map((line) => JSON.parse(line));
                    importedAdditonalParams[paramKey] = lines;
                }
            }
        );

        const modelRegex = /=== MODEL ===\s*(.*?)\s*=== MODEL ===/s;
        const modelMatches = text?.match?.(modelRegex)?.[1];
        this.setState({ initData: "", ...importedAdditonalParams }, () => {
            const modelData = modelMatches?.split(";")?.join?.(";\n");
            modelData &&
                this.convertTextToEquation(modelData, [], (finalText) => {
                    this.setState({ initData: finalText }, () => {
                        this.detectLinesProperVariables();
                    });
                });
        });
    };

    onChangeFile = (event) => {
        if (event.target?.files) {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onload = async (e) => {
                try {
                    const text = e.target.result;
                    const fileContent = JSON.parse(text);
                    this.setState(
                        {
                            indexes: fileContent.indexes,
                            properVariables: fileContent.variables,
                        },
                        () => {
                            const modelData = fileContent.smartInputContent
                                ?.split(";")
                                ?.join?.(";\n");
                            modelData &&
                                this.convertTextToEquation(
                                    modelData,
                                    [],
                                    (finalText) => {
                                        this.setState(
                                            { initData: finalText },
                                            () => { }
                                        );
                                    }
                                );
                        }
                    );

                    //TODO: When hashing needed, implement this part
                    // const file = e.target.files[0];
                    // e.target.value = null;
                    // const fileProps = {
                    //     file,
                    //     name: file?.name,
                    //     type: file?.type,
                    //     size: file?.size,
                    //     lastModified: file?.lastModified,
                    //     lastModifiedDate: file?.lastModifiedDate,
                    //     address: "/or/models/importModelFile",
                    //     field: "field",
                    //     applet: null,
                    // };
                    // const result = await HttpService.fileUpload({
                    //     file,
                    //     fileProps,
                    // });
                    // const excelFileDefinedNames = result.excelFileDefinedNames;
                    // this.setState({
                    //     allAvailableWBNames: excelFileDefinedNames,
                    // });
                } catch (error) {
                    return alert(error);
                } finally {
                    this.setState({ isExcelFileLoading: false });
                }
                // return this.handleImportedTextFile(text);
            };
            reader.readAsText(file);
        }
    };

    mergeArrays(oldArray, newArray) {
        const mergedArray = [...oldArray];

        newArray.forEach((newObj) => {
            const existingObjIndex = mergedArray.findIndex(
                (obj) => obj.name === newObj.name
            );

            if (existingObjIndex !== -1) {
                mergedArray[existingObjIndex] = newObj;
            } else {
                mergedArray.push(newObj);
            }
        });

        return mergedArray;
    }
    mergeArraysKeepOldDesc(oldArray, newArray) {
        const mergedArray = [...oldArray];

        newArray.forEach((newObj) => {
            const existingObjIndex = mergedArray.findIndex(
                (obj) => obj.name === newObj.name
            );

            if (existingObjIndex === -1) {
                mergedArray.push(newObj);
            } else {
                mergedArray[existingObjIndex] = {
                    ...newObj,
                    description: mergedArray[existingObjIndex].description,
                    type: mergedArray[existingObjIndex].type,
                    conditions: mergedArray[existingObjIndex].conditions,
                };
            }
        });

        return mergedArray;
    }

    blobToFile = (blob, fileName) => {
        // var b: any = theBlob;
        let myBlob = blob;
        //A Blob() is almost a File() - it's just missing the two properties below which we will add
        myBlob.lastModifiedDate = new Date();
        myBlob.name = fileName;

        //Cast to a File() type
        return new File([myBlob], fileName, { type: myBlob.type });
    };
    formatParamModelString(array, stringName) {
        const formattedParams = array
            .map((obj) => JSON.stringify(obj))
            .join("\n");

        return `=== ${stringName} ===\n${formattedParams}\n=== ${stringName} ===\n`;
    }
    ExportTextFile = async () => {
        try {
            const text = this.smartInput.getInputValue();
            const modelName = this.state.name;
            const nickName = this.state.nickname;
            const response = await HttpService.fileDownload({
                api: "exportModelFile",
                body: {
                    fileName: `${nickName}-${modelName}`,
                    indexes: [...this.state.indexes],
                    properVariables: [...this.state.properVariables],
                    smartInputContent: text,
                },
            });
        } catch (error) {
            console.log(error);
        }
    };

    doMathOptimizationEngine(line, cb) {
        if (line) {
            // //  // //

            let optimizationObject = { 0: {} };
            let currentStatus = { side: 0, index: 0 };
            // this.proccessChars(
            //     line,
            //     0,
            //     { indexes: [0, 0, 0], type: null, buffer: '' },
            //     optimizationObject,
            //     () => {
            // //  // //
            // return
            // this.postProccess(optimizationObject, (result) => {
            //     // //  // //
            //     // //  // //
            //     cb(result);
            cb();
            // });
            //     }
            // );
        } else {
            // //  // //
            cb();
        }
    }

    postProccess(optimizationObject, cb) {
        if (optimizationObject) {
            let finalRes = {};
            this.proccessSide(optimizationObject, 0, finalRes, () => {
                // //  // //
                // //  // //
                cb(finalRes);
            });
        }
    }

    proccessSide(optimizationObject, index, finalRes, cb) {
        // //  // //
        // //  // //
        // //  // //

        if (optimizationObject[index] && optimizationObject[index].children) {
            let side = optimizationObject[index];
            let sumRes = [];
            let sideRes = {};
            this.processSumLevel(side.children, 0, sumRes, () => {
                // //  // //

                for (let i = 0; i < sumRes.length; i++) {
                    const element = sumRes[i];
                    let variables = Object.keys(element);
                    // delete variables['*']
                    variables.sort((a, b) => a.localeCompare(b));

                    let key = "";

                    for (let j = 0; j < variables.length; j++) {
                        const variableKey = variables[j];
                        // //  // //
                        if (variableKey != "*") {
                            key =
                                key + variableKey + "^" + element[variableKey];
                        }
                        // element.variable + '^' + element.power
                    }
                    if (key == "" && element["*"]) {
                        key = "*";
                    }
                    // //  // //
                    if (key != "") {
                        if (!sideRes[key]) {
                            // if (key == '*') {
                            //     sideRes[key] = element['*']
                            // } else {
                            sideRes[key] = element; //{ factor: 0, variable: element.variable, power: element.power }
                            // }
                        } else {
                            // sideRes[key].factor = sideRes[key].factor + element.factor
                            sideRes[key]["*"] =
                                (sideRes[key]["*"] ?? 1) + (element["*"] ?? 1);
                        }
                    }
                }

                // //  // //
                // //  // //
                finalRes[index] = sideRes;
            });

            // //  // //
            // //  // //
            this.proccessSide(optimizationObject, index + 1, finalRes, cb);
        } else {
            cb();
        }
    }

    processSumLevel(side, index, sumRes, cb) {
        // //  // //
        // //  // //

        if (side[index] && side[index].children) {
            let multipyRes = {};
            this.processMultiplyLevel(
                side[index].children,
                0,
                multipyRes,
                () => {
                    // //  // //
                    let factor = multipyRes["*"];
                    if (factor == null) {
                        factor = 1;
                    }
                    let tempRes = [];
                    let temptemp = {};

                    for (let [key, value] of Object.entries(multipyRes)) {
                        if (typeof value != "object") {
                            temptemp[key] = value;
                        }
                    }

                    tempRes.push(temptemp);

                    // //  // //

                    for (let [key, value] of Object.entries(multipyRes)) {
                        if (typeof value == "object") {
                            // //  // //

                            let newTemp = [];
                            for (let i = 0; i < tempRes.length; i++) {
                                let element = tempRes[i];
                                //    //  // //
                                //     //  // //
                                //     //  // //

                                for (let [ckey, cvalue] of Object.entries(
                                    value
                                )) {
                                    // //  // //

                                    // let found = false
                                    // const element = tempRes[i];
                                    let thisTemp = { ...cvalue };
                                    // //  // //
                                    if (element["*"] == null) {
                                        element["*"] = 1;
                                    }
                                    for (let [dkey, dvalue] of Object.entries(
                                        element
                                    )) {
                                        if (dkey == "*") {
                                            // //  // //
                                            // //  // //
                                            // //  // //
                                            thisTemp[dkey] =
                                                (thisTemp[dkey] ?? 1) *
                                                (dvalue ?? 1);
                                        } else {
                                            if (thisTemp[dkey] != null) {
                                                thisTemp[dkey] =
                                                    (thisTemp[dkey] ?? 1) +
                                                    (dvalue ?? 1);
                                            } else {
                                                thisTemp[dkey] = dvalue ?? 1;
                                            }
                                        }
                                    }
                                    // //  // //
                                    newTemp.push({ ...thisTemp });

                                    // if (cvalue.variable == element.variable) {
                                    //     // tempRes[i].power = tempRes[i].power + cvalue.power
                                    //     // tempRes[i].factor = tempRes[i].factor * cvalue.factor
                                    //     found = true
                                    // }
                                }

                                // if (!found) {
                                //     tempRes.push({ factor: cvalue.factor, variable: cvalue.variable, power: cvalue.power })
                                // }
                            }

                            tempRes = [...newTemp];
                            // //  // //
                        }
                    }

                    for (let i = 0; i < tempRes.length; i++) {
                        const element = tempRes[i];
                        sumRes.push(element);
                    }

                    // sumRes = [...sumRes,...tempRes]
                    // if (Object.keys(multipyRes).length == 1 && multipyRes["*"]) {
                    //     sumRes.push({ factor: multipyRes["*"], variable: '*', power: 1 })
                    // }

                    this.processSumLevel(side, index + 1, sumRes, cb);
                }
            );
        } else {
            // //  // //
            cb();
        }
    }

    processMultiplyLevel(sumLevel, index, multipyRes, cb) {
        // let final = {}
        // //  // //
        // //  // //
        if (sumLevel[index]) {
            if (typeof sumLevel[index].value == "object") {
                // //  // //
                // //  // //

                this.handelObjectValue(sumLevel[index].value, (result) => {
                    // //  // //
                    // //  // //

                    multipyRes[nanoid()] = result[0];
                    this.processMultiplyLevel(
                        sumLevel,
                        index + 1,
                        multipyRes,
                        cb
                    );
                });
            } else {
                // //  // //
                // //  // //
                if (Number(sumLevel[index].value)) {
                    if (!multipyRes["*"]) {
                        multipyRes["*"] = 1;
                    }
                    multipyRes["*"] =
                        multipyRes["*"] * (sumLevel[index].value ?? 1);
                } else {
                    let key = sumLevel[index].value;
                    let invert = false;
                    key = key.replaceAll("+", "");
                    if (key.includes("-")) {
                        key = key.replaceAll("-", "");
                        invert = true;
                    }
                    if (!multipyRes[key]) {
                        multipyRes[key] = 0;
                    }
                    multipyRes[key] = multipyRes[key] + 1;
                    if (invert) {
                        multipyRes["*"] = -1 * (multipyRes["*"] ?? 1);
                    }
                }
                // //  // //
                this.processMultiplyLevel(sumLevel, index + 1, multipyRes, cb);
            }
        } else {
            // //  // //
            // //  // //
            cb();
        }
    }

    handelObjectValue(value, cb) {
        let sectionResult = {};
        this.proccessSide({ 0: { children: value } }, 0, sectionResult, () => {
            // //  // //
            // //  // //
            cb(sectionResult);
        });
    }

    proccessChars(line, lineIndex, currentStatus, optimizationObject, cb) {
        let char = line[lineIndex];
        // //  // //
        // //  // //
        // //  // //

        if (char) {
            let indexes = currentStatus.indexes;
            let newStatus = { latestOperator: currentStatus.latestOperator };
            let newBuffer;

            this.getCharType(char, (type) => {
                // if (lineIndex == 0) {
                // if (currentStatus.type == type) {
                // } else {
                //     newBuffer = char
                // }
                newBuffer = currentStatus.buffer;

                // //  // //
                if (type == "sideLevel") {
                    if (currentStatus.type != "sideLevel") {
                        indexes = [indexes[0] + 1, 0, 0];
                        // this.addToTree(optimizationObject, indexes, 0, { value: newBuffer }, 'children*', true)
                        // this.addToTree(optimizationObject, indexes, 0, char, 'char')

                        setObject(
                            optimizationObject,
                            indexes[0] + ".char",
                            char
                        );
                        newStatus.latestOperator = null;
                    }
                    newBuffer = "";
                    char = null;
                } else if (type == "sumLevel" && currentStatus.char != "*") {
                    let key =
                        indexes[0] +
                        ".children." +
                        indexes[1] +
                        ".children." +
                        indexes[2];
                    // let charKey = indexes[0] + '.children.' + indexes[1] +'.char'
                    if (
                        (newBuffer &&
                            newBuffer != "" &&
                            this.getCharType(newBuffer) == "string") ||
                        this.getCharType(newBuffer) == "number"
                    ) {
                        // //  // //
                        // //  // //
                        setObject(optimizationObject, key, {
                            value: newBuffer,
                            type,
                            char: currentStatus.latestOperator,
                        });
                    }
                    // setObject(optimizationObject, charKey, char)

                    // this.addToTree(optimizationObject, indexes, 0, { [indexes[2]]: { value: newBuffer, type, char } }, 'children', true)
                    // //  // //
                    if (
                        currentStatus.char != null &&
                        currentStatus.char != ""
                    ) {
                        indexes[1] = indexes[1] + 1;
                        indexes[2] = 0;
                    }

                    newStatus.latestOperator = char;
                    newBuffer = char;
                } else if (type == "multiplyLevel") {
                    // if (indexes[2] == null) {
                    //     indexes[2] = 0
                    // } else {
                    // //  // //
                    // indexes[2] = indexes[2] + 1
                    // }
                    let key =
                        indexes[0] +
                        ".children." +
                        indexes[1] +
                        ".children." +
                        indexes[2];
                    if (
                        (newBuffer &&
                            newBuffer != "" &&
                            this.getCharType(newBuffer) == "string") ||
                        this.getCharType(newBuffer) == "number"
                    ) {
                        // //  // //
                        // //  // //
                        setObject(optimizationObject, key, {
                            value: newBuffer,
                            type,
                            char,
                        });
                    }
                    newStatus.latestOperator = char;
                    indexes[2] = indexes[2] + 1;
                    // this.addToTree(optimizationObject, indexes, 0, { value: newBuffer, type, char }, 'children', true)
                    newBuffer = "";
                } else if (type == "braces") {
                    // //  // //
                    if (char == "(") {
                        this.handelParentheses(
                            line,
                            lineIndex,
                            (parValue, numberOfChars) => {
                                // //  // //
                                // //  // //
                                // //  // //
                                lineIndex = lineIndex + numberOfChars + 1;

                                // indexes[2] = indexes[2] + numberOfChars
                                let key =
                                    indexes[0] +
                                    ".children." +
                                    indexes[1] +
                                    ".children." +
                                    indexes[2];

                                if (currentStatus.latestOperator == "(") {
                                    indexes[2] = indexes[2] + 1;
                                }
                                if (
                                    this.getCharType(currentStatus.char) ==
                                    "number" ||
                                    this.getCharType(currentStatus.char) ==
                                    "string"
                                ) {
                                    // //  // //
                                    setObject(optimizationObject, key, {
                                        value: newBuffer,
                                        type,
                                        char,
                                    });
                                    indexes[2] = indexes[2] + 1;
                                    key =
                                        indexes[0] +
                                        ".children." +
                                        indexes[1] +
                                        ".children." +
                                        indexes[2];
                                }

                                // //  // //
                                setObject(optimizationObject, key, {
                                    value: parValue,
                                    type,
                                    char,
                                });

                                // //  // //
                                // //  // //

                                newStatus.latestOperator = "(";

                                // //  // //
                                // newStatus.latestOperator = '*'
                                // newBuffer = parValue
                            }
                        );
                    }
                    newBuffer = "";
                } else {
                    let key =
                        indexes[0] +
                        ".children." +
                        indexes[1] +
                        ".children." +
                        indexes[2];

                    if (
                        this.getCharType(currentStatus.char) == "number" &&
                        type == "string"
                    ) {
                        // //  // //
                        setObject(optimizationObject, key, {
                            value: newBuffer,
                            type,
                            char,
                        });
                        indexes[2] = indexes[2] + 1;
                        key =
                            indexes[0] +
                            ".children." +
                            indexes[1] +
                            ".children." +
                            indexes[2];
                        newBuffer = char;
                    } else {
                        newBuffer = newBuffer + char;
                    }

                    if (newBuffer && newBuffer != "") {
                        setObject(optimizationObject, key, {
                            value: newBuffer,
                            type,
                            char: currentStatus.latestOperator,
                        });
                    }
                    // newBuffer = ''
                    // this.addToTree(optimizationObject, indexes, 0, { value: newBuffer, type }, 'children', true)
                }

                // //  // //

                // this.addToTree(optimizationObject, indexes, 0, newBuffer,'value')
                //    //  // //
                // optimizationObject[currentStatus.indexes[0]][currentStatus.indexes[1]] = { indexes, type, buffer: currentStatus.buffer + char }
                // }

                newStatus = {
                    ...newStatus,
                    ...{ indexes, type, char, buffer: newBuffer },
                };
                this.proccessChars(
                    line,
                    lineIndex + 1,
                    newStatus,
                    optimizationObject,
                    cb
                );
            });
        } else {
            cb();
        }
    }

    handelParentheses(line, lineIndex, cb) {
        // //  // //
        // //  // //

        let restOfLine = line.substring(lineIndex, line.length);

        // //  // //
        let result = {};
        let partial = "";
        // const regex = /\((.*?)\)/
        this.findPartial(restOfLine, 1, 1, (lastIndex) => {
            // //  // //

            // //  // //
            if (lastIndex != null) {
                // let found = restOfLine.match(regex);
                // //  // //
                // if (found) {
                // partial = found
                // partial = restOfLine.substring(1, partial.length - 1)
                partial = restOfLine.substring(1, lastIndex);

                // //  // //
                // this.proccessChars(
                //     partial,
                //     0,
                //     { indexes: [0, 0, 0], type: null, buffer: '' },
                //     result,
                //     () => {
                //         // //  // //
                //         // //  // //
                //     }
                // );
            }
        });
        if (result && result[0]?.children) {
            result = result[0]?.children;
        }
        cb(result, partial.length);
    }

    findPartial(restOfLine, index, count, cb) {
        // let count = 1
        if (restOfLine[index]) {
            let done = false;
            if (restOfLine[index] == "(") {
                count = count + 1;
            }

            if (restOfLine[index] == ")") {
                count = count - 1;
                if (count == 0) {
                    done = true;
                }
            }

            if (done) {
                cb(index);
            } else {
                this.findPartial(restOfLine, index + 1, count, cb);
            }
        } else {
            cb(null, true);
        }
    }

    addToTree(mainObject, indexes, index, value, key, pushToArray) {
        // //  // //
        // //  // //
        if (indexes.length == index + 1) {
            if (pushToArray) {
                if (!mainObject[key]) {
                    mainObject[key] = [];
                }
                mainObject[key][indexes[index]] = value;
            } else {
                if (!mainObject[indexes[index]]) {
                    mainObject[indexes[index]] = {};
                }
                mainObject[indexes[index]][key] = value;
            }
            return;
        } else {
            if (mainObject[indexes[index]]) {
                this.addToTree(
                    mainObject[indexes[index]],
                    indexes,
                    index + 1,
                    value,
                    key,
                    pushToArray
                );
            } else {
                return;
            }
        }
    }

    getCharType(char, cb) {
        let type = "string";
        if (
            [
                "0",
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                ".",
                "|",
            ].includes(char)
        ) {
            type = "number";
        }

        if (["=", "==", "<", "<=", ">", ">=", "!="].includes(char)) {
            type = "sideLevel";
        }

        if (["+", "-"].includes(char)) {
            type = "sumLevel";
        }

        if (["*", "/"].includes(char)) {
            type = "multiplyLevel";
        }

        if (["(", ")"].includes(char)) {
            type = "braces";
        }
        if (cb) {
            cb(type);
        } else {
            return type;
        }
    }

    equationSections(line, cb) {
        let equations = [];
        let currentEquation = "";
        let openEquations = 0;
        for (let index = 0; index < line.length; index++) {
            const charecter = line[index];
            // //  // //
            if (charecter == "#" && line[index + 1] == "~") {
                if (openEquations == 0 && currentEquation != "") {
                    equations.push({ type: "text", content: currentEquation });
                    currentEquation = "";
                }
                openEquations = openEquations + 1;
            }
            // if (openEquations > 0) {
            currentEquation = currentEquation + charecter;
            // }

            if (charecter == "#" && line[index - 1] == "|") {
                openEquations = openEquations - 1;
                if (openEquations == 0) {
                    currentEquation = currentEquation.replace(/ /g, "");
                    equations.push({
                        type: "equation",
                        content: currentEquation,
                    });
                    currentEquation = "";
                }
            }
        }

        if (currentEquation != "") {
            equations.push({ type: "text", content: currentEquation });
        }
        // //

        cb(equations);
    }

    optimizedToString(optimized, originalLine, isFirstLine) {
        if (optimized) {
            if (!isFirstLine && optimized[0] && optimized[0]["*"]) {
                let temp = optimized[0]["*"]["*"] ?? 0;
                if (
                    temp &&
                    optimized[1] &&
                    optimized[1]["*"] &&
                    optimized[1]["*"]["*"]
                ) {
                    if (optimized[1]["*"] && optimized[1]["*"]["*"]) {
                        optimized[1]["*"]["*"] = optimized[1]["*"]["*"] + temp;
                    } else {
                        optimized[1]["*"] = { "*": temp };
                    }
                    delete optimized[0]["*"];
                }
            }
            let line = "";
            // for (let [index, [okey, ovalue]] of Object.entries(optimized)) {
            for (let i = 0; i < Object.values(optimized).length; i++) {
                const ovalue = Object.values(optimized)[i];

                // //  // //

                for (let [ckey, cvalue] of Object.entries(ovalue)) {
                    let factor = cvalue["*"] != null ? cvalue["*"] : 1;

                    if (factor > 0) {
                        factor = "+" + factor;
                    }

                    if (!isFirstLine || i > 0) {
                        line = line + factor;
                    }
                    // //  // //
                    for (let [key, value] of Object.entries(cvalue)) {
                        if (key != "*") {
                            line =
                                line +
                                (line != "" ? "*" : "") +
                                key +
                                (value > 1 ? "^" + value : "");
                        }
                    }
                }
                if (i == 0) {
                    // //  // //
                    let exp = /(=)|(>=)|(<=)/g;
                    let sign = originalLine.match(exp);
                    if (sign) {
                        sign = sign[0];
                        line = line + sign;
                    }
                }
                // }
            }
            // line = line + ';'
            // //  // //

            return line;
        }
    }

    compileFirstLine(line) {
        // //  // //
        if (line && line != "") {
            let sides = line?.split("=");
            let errors = [];

            if (
                !sides[0] ||
                (sides[0].toLowerCase() != "max" &&
                    sides[0].toLowerCase() != "min")
            ) {
                errors.push({ line: 1, message: "Type of model is not valid" });
            }
            let type = sides[0];

            this.extractVariables(sides[1], (variables, errors) => {
                // //  // //

                if (errors && errors.length > 0) {
                    return errors;
                }

                this.state.variables = variables;
                this.setState({ type });
            });
        }
    }

    compileOtherLine(line, index) {
        if (line && line != "") {
            let exp = /(=)|(>=)|(<=)/g;
            let sign = line.match(exp);
            let sides = line?.split(sign);
            let errors = [];
            let value = Number(sides[1]);

            // // //
            if (isNaN(value)) {
                errors.push({
                    line: index + 1,
                    message: "value is not a number",
                });
                // //  // //
            }

            this.extractVariables(sides[0], (variables, newErrors) => {
                //  // //
                //  // //
                if (errors && errors.length > 0) {
                    errors = [...errors, ...newErrors];
                    // return
                }

                let constraints = this.state.constraints;

                if (sign) {
                    constraints.push({
                        line: index + 1,
                        value,
                        variables,
                        sign: sign[0],
                    });
                }
                this.state.constraints = constraints;

                this.checkForNewVariables(variables);
                this.addErrors(errors);
                // //  // //

                // return errors
                // //  // //
            });
        }
    }

    addErrors = (newErrors) => {
        let errors = [...this.state.errors];
        errors = [...errors, ...newErrors];
        this.setState({ errors });
    };

    checkForNewVariables = (variables) => {
        let currentVariables = this.state.variables;
        if (!currentVariables) {
            currentVariables = [];
        }
        // variables.forEach(variable => {
        if (currentVariables) {
            for (let i = 0; i < variables.length; i++) {
                const variable = variables[i];
                let isAvailable = false;
                currentVariables.forEach((currentVariable) => {
                    if (
                        currentVariable.name.toUpperCase() ==
                        variable.name.toUpperCase()
                    ) {
                        isAvailable = true;
                    }
                });

                if (!isAvailable) {
                    currentVariables.push({
                        name: variable.name.toUpperCase(),
                        value: 0,
                    });
                    // //  // //
                }
            }

            // });

            this.state.currentVariables = currentVariables;
        }
    };

    extractVariables(side, cb) {
        let errors = [];
        let exp = /\+|\-/g;
        // //  // //
        // //  // //

        if (side) {
            let sections = [];
            let sectionsSeperators = [];

            let match;
            let addPlus = false;
            while ((match = exp.exec(side)) !== null) {
                if (match.index != 0 && sectionsSeperators.length == 0) {
                    sectionsSeperators.push({ match: "+", start: 0, end: 1 });
                    addPlus = true;
                }
                let start = addPlus ? match.index + 1 : match.index;
                let end = addPlus ? exp.lastIndex + 1 : exp.lastIndex;

                // //  // //
                sectionsSeperators.push({ match: match[0], start, end });
            }
            if (addPlus) {
                side = "+" + side;
            }

            if (sectionsSeperators.length == 0) {
                sectionsSeperators.push({ match: "+", start: -1, end: 0 });
                addPlus = true;
            }

            // sectionsSeperators.forEach((seperator, index) => {

            for (let index = 0; index < sectionsSeperators.length; index++) {
                const seperator = sectionsSeperators[index];
                let start = seperator.start + 1;
                let end = sectionsSeperators[index + 1]
                    ? sectionsSeperators[index + 1].start
                    : side.length;

                let thisSectionText = side.substring(start, end);
                sections.push({ sign: seperator.match, text: thisSectionText });
            }

            // });

            let variables = [];

            // sections.forEach(section => {

            for (let i = 0; i < sections.length; i++) {
                const section = sections[i];
                let value = 1;
                let name = section.text;
                if (section.text.includes("*")) {
                    let array = section.text?.split("*");
                    value = array[0];

                    name = array[1];
                }

                if (String(value).includes("/")) {
                    value = eval(value).toFixed(3);
                    // //  // //
                }

                if (isNaN(Number(value))) {
                    value = 1;
                    // this.addErrors({})
                }

                if (section.sign == "-") {
                    value = Number(value) * -1;
                }

                let varExp = /^[a-zA-Z0-9_|-]*$/g;
                // //  // //
                if (varExp.test(name)) {
                    variables.push({ name: name.toUpperCase(), value });
                } else {
                    // //  // //
                    let err =
                        "You can only use numbers and letters for a variable name";
                    //  // //
                    alert(err);
                    errors.push(console.err);
                    this.addErrors(errors);
                    // cb(null, errors)
                    return;
                }
            }

            // });

            // //  // //
            cb(variables, errors);
            // //  // //
        }
    }

    submitNewModel = (nickname) => {
        return new Promise((resolve, reject) => {
            HttpService.request(
                "createNewModel",
                { nickname },
                (fetchResult, fetchError) => {
                    if (fetchError) {
                        return reject(fetchError.message);
                    }
                    const modelName = fetchResult.modelName;
                    resolve(modelName);
                }
            );
        });
    };
    submitNickName = async () => {
        if (this.state.nickname && this.state.nickname != "") {
            try {
                const modelName = await this.submitNewModel(
                    this.state.nickname
                );

                window.history.pushState(
                    null,
                    null,
                    pathMaker("/editor/" + modelName)
                );
                this.setState({ name: modelName, isLoadingPage: false }, () => {
                    this.nameModal.hideModal();
                });
            } catch (error) {
                return alert(error.message);
            }
        }
    };

    generateExtendedModel = () => {
        const smartInputContent = this.smartInput.getInputValue();

        if (smartInputContent?.trim() == "") {
            return alert`Please enter a valid model expression!`;
        }

        this.setState({ isModelBeingGeneratedLoader: true }, () => {
            const params = {
                name: this.state.name || "",
                nickname: this.state.nickname || "",
                variables: [...this.state.variables] || [],
                excelDefinedNames:
                    this.excelDefinedNamesComp.getSelectedWBNames() || {},
                properVariables: this.state.properVariables || [],
                indexes: [...this.state.indexes] || [],
                smartInputContent,
            };
            HttpService.request(
                "generateExtendedModel",
                params,
                (fetchResult, fetchError) => {
                    this.setState({}, () => {
                        if (fetchError) {
                            return alert(fetchError?.message);
                        }
                    });
                }
            );
        });
    };

    postEquationToSolvingEngine = (solvingEngine) => {
        const data = {
            name: this.state.name,
            nickname: this.state.nickname,
            smartInputContent: this.smartInput.getInputValue(),
            properVariables: this.state.properVariables,
            indexes: this.state?.indexes,
            variables: this.state.variables,
            helper: this.state.helper,
            type: this.state.type,
            constraints: this.state.constraints,
            solvingEngine,
        };
        // //  // //
        this.setState({ isLoadingPost: true }, () => {
            HttpService.request(
                "postEquationToSolvingEngine",
                data,
                (fetchResult, fetchError) => {
                    this.setState({ isLoadingPost: false }, async () => {
                        if (fetchError) {
                            return alert(fetchError.message);
                        }
                    });
                }
            );
        });
    };

    postEquation = (file, solvingEngine) => {
        const variables = [...this.state?.variables];
        const indexes = [...this.state?.indexes];
        const selectedWBNames = Object.values(
            this.excelDefinedNamesComp.getSelectedWBNames()
        );
        const properVariables = [...this.state.properVariables];
        const filteredProperVariables = properVariables.filter(
            (item1) =>
                !selectedWBNames.some(
                    (item2) =>
                        item2.name.toLowerCase() === item1.name.toLowerCase()
                )
        );
        var text;
        text = this.smartInput.getInputValue();
        let data = {
            name: this.state.name,
            nickname: this.state.nickname,
            solvingEngine,
            constraints: [...this.state.constraints],
            variables: [...this.state.variables],
            properVariables: filteredProperVariables,
        };

        if (file) {
            data.file = file;
        } else {
            data.type = this.state.type;

            data.variables = variables;

            data.text = text;
        }

        if (indexes && indexes.length > 0) {
            data.indexes = JSON.stringify(indexes);
        }
        if (this.state.helper) {
            data.helper = JSON.stringify(this.state.helper);
        }

        // //  // //
        this.setState({ isLoadingPost: true }, () => {
            HttpService.request(
                "postEquation",
                data,
                (fetchResult, fetchError) => {
                    this.setState({ isLoadingPost: false }, async () => {
                        if (fetchError) {
                            return;
                        }

                        // this.resultModal.modal.showModal();
                        //
                        // if (fetchResult) {
                        //     if (!fetchResult.result) {
                        //         fetchResult.result = {};
                        //     }
                        //     if (fetchResult?.fileObject?.elapsed_time) {
                        //         fetchResult.result.time = (
                        //             Number(fetchResult?.fileObject?.elapsed_time) * 1000
                        //         ).toFixed(2);
                        //     } else {
                        //         fetchResult.result.time = fetchResult.time;
                        //     }
                        //     fetchResult.result.insertTime = fetchResult.insertTime;
                        //     fetchResult.result.removeTime = fetchResult.removeTime;
                        //     fetchResult.result.readTime = fetchResult.readTime;

                        //     this.setState({ result: fetchResult.result });

                        //     if (!this.state.name || this.state.name == "") {
                        //         window.history.pushState(
                        //             null,
                        //             null,
                        //             pathMaker("/editor/" + fetchResult.name)
                        //         );

                        //         this.setState({ name: fetchResult.name });
                        //     }
                        // }
                    });
                }
            );
        });
    };
    detectLinesProperVariables = () => {
        const text = this.smartInput.getInputValue();
        const regex = /#~index~\s+(\w+)\s+~~([^~]+)~~/g;
        const matches = [];
        let match;
        while ((match = regex.exec(text)) !== null) {
            const name = match[1];
            const indexes = match[2]?.trim(); // Split indexes by comma and remove surrounding whitespace
            matches.push({
                name: name.toLowerCase(),
                indexes: indexes,
                type: "continuous",
                indexesMultiplicity: this.calculateIndexesMultiplicity(
                    indexes?.split(""),
                    [...this.state?.indexes]
                ),
                description: "",
                conditions: "",
                id: uuid(),
            });
        }
        const existingVariables = matches?.filter((obj, index, arr) => {
            return (
                index ===
                arr.findIndex(
                    (item) =>
                        item.name?.toLowerCase() === obj.name?.toLowerCase()
                )
            );
        });
        const removeDigitRegex = /\d/; // This regex matches any digit
        const newVariablesArray = this.mergeArraysKeepOldDesc(
            [...this.state.properVariables],
            existingVariables
        )
            ?.filter((item) => !removeDigitRegex.test(item?.name))
            .map((properVar) => {
                return {
                    ...properVar,
                    indexes: replaceParenthesisWithItsLetter(properVar.indexes),
                };
            });
        this.setState({ properVariables: newVariablesArray });
        return 0;
    };

    calculateIndexesMultiplicity = (indexes = "", objectsArray) => {
        if (indexes) {
            const result = indexes?.reduce((acc, char) => {
                const matchingObject = objectsArray.find(
                    (obj) => obj.name === char
                );

                if (matchingObject) {
                    return acc * matchingObject.value;
                } else {
                    return acc; // Return the current accumulator without modification
                }
            }, 1);
            return result;
        }
        return 0;
    };

    openIndexesModal = () => {
        this.indexesModalRef.modal.showModal();
    };
    openProperVariables = () => {
        this.detectLinesProperVariables();
        this.properVariablesRef.modal.showModal();
    };

    changeNickName = (e, key) => {
        // let date = this.state.data
        // data[key] = e.target.value
        // this.setState({data})
        this.setState({ nickname: e.target.value });
    };

    updateIndexes = (indexes) => {
        this.setState({ indexes });
    };
    updateProperVariables = (properVariables) => {
        //  // //

        this.setState({ properVariables });
    };

    doc_keyUp = (e) => {
        if (e.altKey && e.key === "v") {
            this.insertEquation("Index");
        }
        if (e.altKey && e.key === "s") {
            this.insertEquation("Sigma");
        }
        if (e.altKey && e.key === "a") {
            this.insertEquation("ForAll");
        }
    };

    solveGeneratedModel = () => {
        const lines = [...this.state.generatedModelExtendedLines];
        lines.forEach(({ convertedLine = "", line ="" }, index) => {
            this.doMathOptimizationEngine(convertedLine, (optimized) => {
                if (index == 0) {
                    let convertedLine = this.optimizedToString(
                        optimized,
                        line,
                        true
                    );

                    this.compileFirstLine(convertedLine);
                    // })
                } else {
                    let convertedLine = this.optimizedToString(
                        optimized,
                        line,
                        false
                    );
                    this.compileOtherLine(convertedLine, index);
                }
            });
        });
        this.configModal.showModal();
    };
    downloadModelFile = async (formatType) => {
        try {
            const modelName = this.state.name;
            const response = await HttpService.fileDownload({
                api: "downloadModelFiles",
                body: {
                    modelName,
                    fileName: this.state.nickname,
                    formatType,
                },
            });
        } catch (error) {
            alert(error?.message + " Regenrate the model.");
        }
    };
    ModelAdditionalParamsList = [
        {
            paramKey: "properVariables",
            refKey: "properVariablesRef",
            page: {
                key: "properVariables",
                title: "proper variables",
                description: "proper variables List",
                fetchUrl: "",
                addUrl: "postValue",
                removeUrl: "removeValue",
                helperKey: "values",
                pageType: "form",
                options: {},
                sort: { cDate: -1 },
                exportTitle: "VARIABLES",
                headers: [
                    {
                        key: "name",
                        type: "TextInput",
                        col: "3",
                        information: {
                            label: "{{lang}}name",
                            maxLength: 15,
                            placeholder: "{{lang}}insertName",
                            pattern: "^[a-z](?:_?[a-z0-9]+)*$",
                            required: true,
                        },
                    },
                    {
                        key: "type",
                        type: "SelectVariableType",
                        col: "3",
                        information: {
                            label: "{{lang}}Type",
                            placeholder: "{{lang}}type",
                            required: true,
                        },
                        listOptions: [
                            {
                                key: "continuous",
                                value: "Continuous",
                            },
                            {
                                key: "binary",
                                value: "Binary",
                            },
                            {
                                key: "integer",
                                value: "Integer",
                            },
                        ],
                    },
                    {
                        key: "conditions",
                        type: "TextAreaInput",
                        col: "6",
                        information: {
                            label: "{{lang}}conditions",
                            placeholder: "{{lang}}conditions",
                            required: false,
                        },
                    },
                    {
                        key: "description",
                        type: "TextAreaInput",
                        col: "6",
                        information: {
                            label: "{{lang}}description",
                            placeholder: "{{lang}}description",
                            required: false,
                        },
                    },
                ],
            },
        },
        {
            paramKey: "indexes",
            refKey: "indexesModalRef",
            page: {
                key: "variables",
                title: "variables",
                description: "variables List",
                fetchUrl: "getValuesWithData",
                addUrl: "postValue",
                removeUrl: "removeValue",
                helperKey: "values",
                pageType: "form",
                options: {},
                sort: { cDate: -1 },
                exportTitle: "INDEXES",
                headers: [
                    {
                        key: "name",
                        type: "TextInput",
                        col: "6",
                        information: {
                            label: "{{lang}}name",
                            maxLength: 1,
                            placeholder: "{{lang}}insertName",
                            pattern: "^[a-z](?:_?[a-z0-9]+)*$",
                            required: true,
                        },
                    },
                    {
                        key: "value",
                        type: "NumberInput",
                        col: "6",
                        information: {
                            label: "{{lang}}Value",
                            placeholder: "{{lang}}value",
                            required: true,
                        },
                    },
                    {
                        key: "description",
                        type: "TextAreaInput",
                        col: "12",
                        information: {
                            label: "{{lang}}description",
                            placeholder: "{{lang}}description",
                            required: false,
                        },
                    },
                ],
            },
        },
    ];
    render() {
        return (
            <Configurer
                settingsInfo={{
                    showFooter: true,
                    showTabBar: true,
                    showHeader: true,
                    headerTitle: "Editor",
                    button: {},
                }}
                title={"Editor"}
                description={"Editor Description"}
                className=" py-5"
                style={{ backgroundColor: "#f2f6f8", minHeight: "100vh" }}
            >
                <div className="container">
                    <div className="flex row ">
                        <ExcelDefinedNames
                            ref={(el) => (this.excelDefinedNamesComp = el)}
                            parseExcelDefinedNames={this.generateExtendedModel}
                        />

                        <div
                            className="col-12 col-md-5 order-1 order-md-1 mb-4 h-auto editor_tools_box"
                            style={{ color: "#89a" }}
                        >
                            <div
                                className=""
                                style={{
                                    backgroundColor: "#fff",
                                    borderRadius: 15,
                                    border: "1px solid #e4e8f2",
                                }}
                            >
                                <div
                                    className="flexcb px-3 py-2"
                                    style={{
                                        backgroundColor: "#e2eaf2",
                                        borderRadius: "12px 12px 0px 0px",
                                    }}
                                >
                                    <p className="text-normal">Tools</p>
                                </div>

                                <div
                                    className="py-4 px-4"
                                    style={{ height: "312px" }}
                                >
                                    <div className="row">
                                        {this.state.tools.map((prop, index) => {
                                            return (
                                                <button
                                                    onClick={() =>
                                                        prop?.function &&
                                                        this?.[prop.function]()
                                                    }
                                                    className="col-2 col-md-4 text-center mb-2 position-relative"
                                                    key={index}
                                                >
                                                    <img
                                                        src={prop.icon}
                                                        width="30px"
                                                        alt=""
                                                    />
                                                    <p
                                                        className="mt-1"
                                                        style={{
                                                            color:
                                                                "rgb(136, 153, 170)",
                                                        }}
                                                    >
                                                        {prop.name}
                                                    </p>

                                                    {prop?.stateKey &&
                                                        this.state[
                                                            prop?.stateKey
                                                        ].length > 0 && (
                                                            <div
                                                                className="flexcc"
                                                                style={{
                                                                    borderRadius: 20,
                                                                    position:
                                                                        "absolute",
                                                                    top: 0,
                                                                    right: 5,
                                                                    backgroundColor:
                                                                        "#ee5050",
                                                                    color:
                                                                        "#fff",
                                                                    height: 20,
                                                                    minWidth: 20,
                                                                }}
                                                            >
                                                                <p>
                                                                    {
                                                                        this
                                                                            .state[
                                                                            prop
                                                                                ?.stateKey
                                                                        ].length
                                                                    }
                                                                </p>
                                                            </div>
                                                        )}
                                                </button>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div
                            className="col-12 col-md-12 order-2 order-md-1 mb-4"
                            style={{ color: "#89a" }}
                        >
                            <div
                                className="w-100"
                                style={{
                                    backgroundColor: "#fff",
                                    borderRadius: 15,
                                    border: "1px solid #e4e8f2",
                                }}
                            >
                                <div
                                    className="flexcb px-3 py-2 w-100"
                                    style={{
                                        backgroundColor: "#e2eaf2",
                                        borderRadius: "12px 12px 0px 0px",
                                    }}
                                >
                                    {/* <p className="text-normal" >{this.state.nickname}</p> */}

                                    <input
                                        onChange={(e) =>
                                            this.setState({
                                                nickname: e.target.value,
                                            })
                                        }
                                        value={this.state.nickname}
                                        style={{
                                            backgroundColor: "transparent",
                                            border: "none",
                                            color: "#789",
                                            fontSize: 16,
                                        }}
                                    />

                                    <button
                                        className=""
                                        style={{
                                            backgroundImage:
                                                "linear-gradient(#789,#678)",
                                            background: "rgb(57,111,180)",
                                            borderRadius: 6,
                                            padding: "5px 10px",
                                            width: "100px",
                                        }}
                                        onClick={this.generateExtendedModel}
                                    >
                                        <span className="white text-smaller">
                                            Generate
                                        </span>
                                    </button>
                                </div>
                                {this.state.isLoadingPage ? (
                                    <div className="flexcc mx-2 p-5 w-100">
                                        <Loader
                                            type={"Oval"}
                                            color={"rgb(57,111,180)"}
                                            height={50}
                                            width={50}
                                        />
                                    </div>
                                ) : (
                                    <div
                                        className="flexcc pb-3"
                                        style={{
                                            minHeight: 300,
                                            maxHeight: "75vh",
                                            overflow: "auto",
                                        }}
                                    >
                                        <SmartInput
                                            ref={(el) => (this.smartInput = el)}
                                            data={this.state.initData}
                                            detectLinesProperVariables={
                                                this.detectLinesProperVariables
                                            }
                                            properVariables={[
                                                ...this.state.properVariables,
                                            ]}
                                            getOneEquation={(clipBoardText) =>
                                                this.getOneEquationFromClipboard(
                                                    clipBoardText
                                                )
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="flex row my-1">
                        <div className="generated_model_container col-12 col-md-12 ">
                            <div className="header px-3">
                                <div className="text">
                                    <div className="ImportData">
                                        <span
                                            style={{
                                                fontSize: "14px",
                                                padding: "5px 0",
                                            }}
                                        >
                                            Generated Model
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div
                                className=" "
                                style={{ backgroundColor: "white" }}
                            >
                                <div
                                    contentEditable={true}
                                    style={{
                                        height: "300px",
                                        outline: "none",
                                        border: "none",
                                        whiteSpace: "pre-wrap",
                                        overflowY: "auto",
                                    }}
                                >
                                    {/* */}
                                    {this.state?.isModelBeingGeneratedLoader ? (
                                        <div
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Loader
                                                type={"Oval"}
                                                color={"rgb(57,111,180)"}
                                                height={100}
                                                width={100}
                                            />
                                        </div>
                                    ) : (
                                        this.state?.generatedModelExtendedLines?.map(
                                            (convertedLine, index) => {
                                                return (
                                                    <p
                                                        style={{
                                                            maxWidth: "100%",
                                                            wordWrap:
                                                                "break-word",
                                                        }}
                                                    >
                                                        {convertedLine}
                                                    </p>
                                                );
                                            }
                                        )
                                    )}
                                    <div>
                                        {this.state?.generatedModelExtendedLines
                                            ?.length > 0
                                            ? "..."
                                            : ""}
                                    </div>
                                </div>

                                <div className="footer col-12">
                                    <div
                                        className="download"
                                        onClick={() =>
                                            this.modelDownloadRef.showModal()
                                        }
                                    >
                                        <img
                                            className="icon"
                                            src="/icons/download.png"
                                            alt="download"
                                        />
                                        <div className="text">
                                            Download the File
                                        </div>
                                    </div>
                                    <div
                                        className="solve"
                                        onClick={() =>
                                            this.solverModal.showModal()
                                        }
                                    >
                                        Solve
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="editor_solving_section">
                        <div className=""></div>
                    </div>
                    {this.ModelAdditionalParamsList.map(
                        ({ paramKey, refKey, page }) => {
                            return (
                                <>
                                    <ModelAdditionalParams
                                        ref={(el) => (this[refKey] = el)}
                                        data={[...this.state[paramKey]]}
                                        updateData={(data) =>
                                            this.setState({
                                                [paramKey]: data,
                                            })
                                        }
                                        page={page}
                                    />
                                </>
                            );
                        }
                    )}

                    <Modal
                        ref={(el) => (this.configModal = el)}
                        maxWidth={"70%"}
                    >
                        <div
                            className="w-100  text-center pb-5"
                            style={{
                                backgroundColor: "#ffffffee",
                                borderRadius: 8,
                            }}
                        >
                            <div
                                className="w-100 flexcb"
                                style={{
                                    zIndex: 1,
                                    padding: "8px 15px",
                                    position: "sticky",
                                    top: 0,
                                    borderBottom: "0px #eee solid",
                                    backgroundColor: "#f2f6f8ee",
                                    background:
                                        "linear-gradient(to right,#d7e2f7dd,#dad6e4dd)",
                                    backdropFilter: "blur(10px) saturate(180%)",
                                    WebkitBackdropFilter:
                                        "blur(10px) saturate(180%)",
                                    borderRadius: "8px 8px 0px 0px",
                                }}
                            >
                                <div className="text-start">
                                    {/* <p
                    className="text-smallest mt-2 opacity-5"
                    style={{ lineHeight: 0.5 }}
                  >
                    Model's Information
                  </p> */}
                                    <p>Model's Information</p>
                                </div>

                                <div className="flexc">
                                    {!this.state.isLoadingPost ? (
                                        <button
                                            className=" flexc px-3 mx-1 flexcc"
                                            style={{
                                                height: 30,
                                                background: "#000510",
                                                borderRadius: 20,
                                            }}
                                        >
                                            <p className="text-normal white">
                                                Solve
                                            </p>
                                        </button>
                                    ) : (
                                        <div className="flexcc mx-2 pt-2">
                                            <Loader
                                                type={"Oval"}
                                                color={"#202020"}
                                                height={30}
                                                width={30}
                                            />
                                        </div>
                                    )}

                                    <div
                                        className="cursor-pointer flexcc"
                                        onClick={() =>
                                            this.configModal.hideModal()
                                        }
                                        style={{
                                            width: 30,
                                            height: 30,
                                            borderRadius: "50%",
                                            backgroundColor: "#ffffff50",
                                        }}
                                    >
                                        <img
                                            style={{ width: 14, height: 14 }}
                                            src="/images/close.svg"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="py-3 px-4">
                                <p
                                    style={{ color: "#845EC2" }}
                                    className="mt-2 text-normal "
                                >
                                    Features of model
                                </p>
                                <table className="w-100 mt-2 lined-table">
                                    <thead>
                                        <tr>
                                            <th>Feature</th>
                                            <th>Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Type of model</td>
                                            <td>{this.state.type}</td>
                                        </tr>
                                        <tr>
                                            <td>Number of Variable</td>
                                            <td>
                                                {this.state.variables?.length}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Number of constraints</td>
                                            <td>
                                                {this.state.constraints?.length}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <p
                                    style={{ color: "#845EC2" }}
                                    className="mt-4 mb-2 text-normal flexcc"
                                >
                                    Objective function coefficients (C){" "}
                                    <span
                                        style={{
                                            backgroundColor:
                                                "rgb(132, 94, 194)",
                                            height: 15,
                                            width: 15,
                                            borderRadius: 30,
                                            color: "#fff",
                                            cursor: "pointer",
                                        }}
                                        className="flexcc mx-1"
                                        onClick={() =>
                                            this.setState({
                                                objectiveCollapse: !this.state
                                                    .objectiveCollapse,
                                            })
                                        }
                                    >
                                        {this.state.objectiveCollapse
                                            ? "+"
                                            : "-"}
                                    </span>
                                </p>
                                <Collapsible
                                    open={!this.state.objectiveCollapse}
                                >
                                    <div
                                        style={{
                                            maxHeight: 300,
                                            overflow: "auto",
                                        }}
                                    >
                                        <table className="w-100 mt-3 lined-table">
                                            <thead
                                                style={{
                                                    position: "sticky",
                                                    top: 0,
                                                }}
                                            >
                                                <tr>
                                                    <th>Variable</th>
                                                    <th>Value</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.variables?.map(
                                                    (prop, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    C{index + 1}{" "}
                                                                    <span
                                                                        style={{
                                                                            color:
                                                                                "#789",
                                                                            fontSize: 12,
                                                                        }}
                                                                    >
                                                                        (
                                                                        {
                                                                            prop.name
                                                                        }
                                                                        )
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    {prop.value}
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </Collapsible>

                                <p
                                    style={{ color: "#845EC2" }}
                                    className="mt-4 mb-2 text-normal flexcc"
                                >
                                    Technical coefficients of constraints (a_ij){" "}
                                    <span
                                        style={{
                                            backgroundColor:
                                                "rgb(132, 94, 194)",
                                            height: 15,
                                            width: 15,
                                            borderRadius: 30,
                                            color: "#fff",
                                            cursor: "pointer",
                                        }}
                                        className="flexcc mx-1"
                                        onClick={() =>
                                            this.setState({
                                                technicalCollapse: !this.state
                                                    .technicalCollapse,
                                            })
                                        }
                                    >
                                        {this.state.technicalCollapse
                                            ? "+"
                                            : "-"}
                                    </span>
                                </p>
                                <Collapsible
                                    open={!this.state.technicalCollapse}
                                >
                                    <div
                                        style={{
                                            maxHeight: 300,
                                            overflow: "auto",
                                        }}
                                    >
                                        <table className="w-100 mt-3 lined-table">
                                            <thead
                                                style={{
                                                    position: "sticky",
                                                    top: 0,
                                                }}
                                            >
                                                <tr>
                                                    <th>
                                                        Constraints\variable
                                                    </th>
                                                    {this.state.variables?.map(
                                                        (prop, index) => {
                                                            return (
                                                                <th className="text-center">
                                                                    V{index + 1}
                                                                    <p className="text-smaller">
                                                                        {
                                                                            prop.name
                                                                        }
                                                                    </p>
                                                                </th>
                                                            );
                                                        }
                                                    )}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.constraints?.map(
                                                    (constraint, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    constraint{" "}
                                                                    {index + 1}
                                                                </td>
                                                                {this.state.variables?.map(
                                                                    (
                                                                        variable,
                                                                        indexv
                                                                    ) => {
                                                                        return (
                                                                            <td
                                                                                key={
                                                                                    indexv
                                                                                }
                                                                            >
                                                                                {constraint
                                                                                    .variables[
                                                                                    variable
                                                                                        .name
                                                                                ]
                                                                                    ?.value ??
                                                                                    0}
                                                                            </td>
                                                                        );
                                                                    }
                                                                )}
                                                            </tr>
                                                        );
                                                    }
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </Collapsible>

                                <p
                                    style={{ color: "#845EC2" }}
                                    className="mt-4 mb-2 text-normal flexcc"
                                >
                                    Resource values (b){" "}
                                    <span
                                        style={{
                                            backgroundColor:
                                                "rgb(132, 94, 194)",
                                            height: 15,
                                            width: 15,
                                            borderRadius: 30,
                                            color: "#fff",
                                            cursor: "pointer",
                                        }}
                                        className="flexcc mx-1"
                                        onClick={() =>
                                            this.setState({
                                                resourceCollapse: !this.state
                                                    .resourceCollapse,
                                            })
                                        }
                                    >
                                        {this.state.resourceCollapse
                                            ? "+"
                                            : "-"}
                                    </span>
                                </p>
                                <Collapsible
                                    open={!this.state.resourceCollapse}
                                >
                                    <div
                                        style={{
                                            maxHeight: 300,
                                            overflow: "auto",
                                        }}
                                    >
                                        <table className="w-100 mt-3 lined-table">
                                            <thead
                                                style={{
                                                    position: "sticky",
                                                    top: 0,
                                                }}
                                            >
                                                <tr>
                                                    <th>Constraints</th>
                                                    <th>Symbol</th>
                                                    <th>sign</th>
                                                    <th>Value</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.constraints?.map(
                                                    (constraint, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    constraint{" "}
                                                                    {index + 1}
                                                                </td>
                                                                <td>
                                                                    b{index + 1}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        constraint.sign
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        constraint.value
                                                                    }
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </Collapsible>
                            </div>
                        </div>
                    </Modal>

                    <ResultModal
                        ref={(el) => (this.resultModal = el)}
                        data={this.state.result}
                        variables={[...this.state.variables]}
                        type={this.state.type}
                        constraints={this.state.constraints}
                    />

                    <Modal
                        ref={(el) => (this.nameModal = el)}
                        maxWidth={"30%"}
                        notClosable={true}
                    >
                        <div
                            className="text-center"
                            style={{
                                backgroundColor: "#fff",
                                borderRadius: 8,
                                padding: "30px",
                            }}
                        >
                            <h1 className="text-big">Model's Name</h1>
                            <p className="text-small mb-4">
                                Type in a name for your model
                            </p>
                            <form action=""></form>

                            <input
                                onChange={(e) => this.changeNickName(e, "name")}
                                placeholder="Name ..."
                                className="w-100"
                                style={{
                                    fontWeight: "bold",
                                    color: "#101010",
                                    border: "#eee",
                                    backgroundColor: "#f2f6f8",
                                    borderRadius: 8,
                                    padding: "10px 20px",
                                    fontSize: 20,
                                }}
                            ></input>
                            <p
                                className="text-small mt-3"
                                style={{ color: "#789" }}
                            >
                                This name will be used for future references
                            </p>

                            <button
                                onClick={() => this.submitNickName()}
                                className="mt-3"
                                style={{
                                    color: "#fff",
                                    backgroundColor: "rgb(57,111,180)",
                                    borderRadius: 8,
                                }}
                            >
                                <p
                                    className="text-normal"
                                    style={{ padding: 5 }}
                                >
                                    Submit
                                </p>
                            </button>

                            <button
                                onClick={() => this.props.history.goBack()}
                                className="mt-3 flexcc text-small text-center w-100 text-bold"
                                style={{ color: "#789" }}
                            >
                                <p>Back to last page?</p>
                            </button>
                        </div>
                    </Modal>

                    <Modal
                        ref={(el) => (this.solverModal = el)}
                        maxWidth={"50%"}
                        notClosable={false}
                    >
                        <SelectInput
                            showOptions={true}
                            selectFunction={(value) => {
                                this.postEquationToSolvingEngine(
                                    value.optionId
                                );
                                this.solverModal.hideModal();
                            }}
                            selectHolder={{
                                styles: {
                                    minWidth: 100,
                                    cursor: "pointer",
                                },
                                className: "",
                            }}
                            selectOptions={{
                                holderStyle: {},
                                className: "",
                                selectedValue: {
                                    defaultText: "Solving Engine",
                                    style: {
                                        fontSize: 25,
                                        height: "100%",
                                        borderRadius: 8,

                                        minHeight: 70,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        background:
                                            "linear-gradient(to right, rgb(0, 122, 255), rgba(0, 122, 255, 0.867))",
                                    },
                                    className: ``,
                                },
                                list: {
                                    holderStyle: {},
                                    className: ``,
                                    options: [
                                        {
                                            optionId: "RE",
                                            option: "Rasha Engine",
                                        },
                                        {
                                            optionId: "GE",
                                            option: "Gurobi Engine",
                                        },
                                        {
                                            optionId: "CE",
                                            option: "COPT Engine",
                                        },
                                    ],
                                    singleOption: {
                                        divStyle: {
                                            zIndex: 100,
                                            fontSize: 25,
                                            minHeight: 70,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            background: "#fff",
                                            borderRadius: 8,
                                            marginTop: 10,
                                        },
                                        divClassName: ``,
                                        labelStyle: {
                                            cursor: "pointer",
                                        },
                                        labelClassName: "",
                                        inputStyle: {
                                            // display: "none",
                                        },
                                    },
                                },
                            }}
                            optionName="option"
                            isEditable={true}
                            alwaysShowOptions={true}
                        />
                    </Modal>
                    <Modal
                        ref={(el) => (this.modelDownloadRef = el)}
                        maxWidth={"50%"}
                        notClosable={false}
                    >
                        <SelectInput
                            showOptions={true}
                            selectFunction={(value) =>
                                this.downloadModelFile(value.optionId)
                            }
                            selectHolder={{
                                styles: {
                                    minWidth: 100,
                                    cursor: "pointer",
                                },
                                className: "",
                            }}
                            selectOptions={{
                                holderStyle: {},
                                className: "",
                                selectedValue: {
                                    defaultText: "Format Types",
                                    style: {
                                        fontSize: 25,
                                        height: "100%",
                                        borderRadius: 8,

                                        minHeight: 70,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        background:
                                            "linear-gradient(to right, rgb(0, 122, 255), rgba(0, 122, 255, 0.867))",
                                    },
                                    className: ``,
                                },
                                list: {
                                    holderStyle: {},
                                    className: ``,
                                    options: [
                                        {
                                            optionId: "OF",
                                            option: "Original Format",
                                        },
                                        {
                                            optionId: "MF",
                                            option: "Mathematical Format",
                                        },
                                        {
                                            optionId: "SG",
                                            option: "Solved Model Excel File",
                                        },
                                        {
                                            optionId: "SL",
                                            option: "Solved Model Logs",
                                        },
                                    ],
                                    singleOption: {
                                        divStyle: {
                                            zIndex: 100,
                                            fontSize: 25,
                                            minHeight: 70,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            background: "#fff",
                                            borderRadius: 8,
                                            marginTop: 10,
                                        },
                                        divClassName: ``,
                                        labelStyle: {
                                            cursor: "pointer",
                                        },
                                        labelClassName: "",
                                        inputStyle: {
                                            // display: "none",
                                        },
                                    },
                                },
                            }}
                            optionName="option"
                            isEditable={true}
                            alwaysShowOptions={true}
                        />
                    </Modal>
                    <Modal
                        ref={(el) => (this.equationsModal = el)}
                        maxWidth={"50%"}
                        notClosable={false}
                    >
                        <div
                            className="text-start"
                            style={{
                                backgroundColor: "#fff",
                                borderRadius: 8,
                                paddingBottom: 5,
                            }}
                        >
                            <div
                                style={{
                                    borderRadius: "8px 8px 0px 0px",
                                    padding: "10px 10px",
                                    background:
                                        "linear-gradient(to left,#007CD3,#656EC9)",
                                    color: "#fff",
                                }}
                            >
                                <p className="text-semibig">Equations</p>
                            </div>

                            {this.state.equations.map((prop, index) => {
                                return (
                                    <button
                                        onClick={() =>
                                            this.insertEquation(prop.key)
                                        }
                                        key={index}
                                        className="w-100 flexcb"
                                        style={{
                                            padding: "8px 15px",
                                            borderTop: "1px solid #eee",
                                        }}
                                    >
                                        <p className="">
                                            {prop.title} | ({prop.shortcut})
                                        </p>
                                        {prop.notation == "INDEX" ? (
                                            <p
                                                style={{
                                                    fontFamily: "poppins",
                                                }}
                                            >
                                                X{" "}
                                                <span
                                                    style={{
                                                        position: "relative",
                                                        top: 5,
                                                        fontSize: "80%",
                                                    }}
                                                >
                                                    i
                                                </span>
                                            </p>
                                        ) : (
                                            <p
                                                style={{
                                                    fontFamily: "poppins",
                                                }}
                                            >
                                                {prop.notation}
                                            </p>
                                        )}
                                    </button>
                                );
                            })}
                        </div>
                    </Modal>
                </div>
            </Configurer>
        );
    }
}

const mapStateToProps = (state) => ({
    settings: state.settings,
    user: state.user,
    socket: state.socket,
});
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Editor);
